'use strict';

Gri.module({
  name: 'select-alpha',
  ieVersion: null,
  $el: $('.select-alpha'),
  container: '.select-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $selectDOM = $this.find('select');
    // endregion

    // region run
    $selectDOM.selectpicker();
    // endregion

    // region events
    $this.find('i').on('click', function () {
      $(this).prev().find('button').trigger('click');
    });
    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
